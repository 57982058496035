import React from 'react'
import { TrustedHtml } from 'shared'

export const SeoText = ({ text }: { text: NullableString }): JSX.Element | null => {
  if (!text) return null

  return (
    <div className="container">
      <div className="row">
        <TrustedHtml as="div" className="col-md-16 offset-md-4 mt-6">
          {text}
        </TrustedHtml>
      </div>
    </div>
  )
}
