export class Bounds {
  static fromGoogleBounds(bounds: google.maps.LatLngBounds, label: string) {
    return new Bounds(
      bounds.getNorthEast().lat(),
      bounds.getNorthEast().lng(),
      bounds.getSouthWest().lat(),
      bounds.getSouthWest().lng(),
      label
    )
  }

  static fromParams(params: string | undefined, boundsLabel: string | undefined) {
    if (!params || !boundsLabel) return
    const [north, east, south, west] = decodeURIComponent(params).split(',').map(parseFloat)
    const label = decodeURIComponent(boundsLabel)
    return new Bounds(north, east, south, west, label)
  }

  constructor(
    public north: number = 0,
    public east: number = 0,
    public south: number = 0,
    public west: number = 0,
    public label: string = ''
  ) {}

  /**
   * @field cappedEast
   * @desc The world is round and 180/-180 actually means the same position. Our API cannot translate these cases, b/c it performs plain float comparison. This is why we got to cap the value to a maximum here.
   */
  get cappedEast() {
    if (this.east <= 0 && this.west >= 0) {
      if (Math.abs(this.east) > Math.abs(this.west)) {
        return 180
      } else {
        return this.east
      }
    } else {
      return this.east
    }
  }

  /**
   * @field cappedWest
   * @desc The world is round and 180/-180 actually means the same position. Our API cannot translate these cases, b/c it performs plain float comparison. This is why we got to cap the value to a maximum here.
   */
  get cappedWest() {
    if (this.east <= 0 && this.west >= 0) {
      if (Math.abs(this.east) < Math.abs(this.west)) {
        return -180
      } else {
        return this.west
      }
    } else {
      return this.west
    }
  }

  toParams(): string {
    return `${this.north},${this.east},${this.south},${this.west}&boundsLabel=${this.label}`
  }
}
