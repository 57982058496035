import React, { useCallback } from 'react'

import classNames from 'classnames'
import styles from './project_search.module.css'
import { FundraisingEventTeaser } from 'betterplace-react-components'
import { ProjectRenderer, ProjectSearchProps, SearchState } from './types'
import { ProjectTeaserWithTracking, RecordList, useSearch } from 'shared'

export const ProjectSearch = ({ apiBaseUrl, gtmList, ...search }: ProjectSearchProps): JSX.Element => {
  const searchResult: SearchState = useSearch({ apiBaseUrl, ...search })

  const renderTeaser: ProjectRenderer = useCallback(
    (result, index) => {
      if (result.type === 'FundraisingEvent') return <FundraisingEventTeaser fundraisingEvent={result} />
      return <ProjectTeaserWithTracking project={result} index={index} trackingPath="/discover/" gtmList={gtmList} />
    },
    [gtmList]
  )

  return (
    <div className={classNames(styles.container, 'container')}>
      <RecordList {...searchResult} render={renderTeaser} fancyButton />
    </div>
  )
}
