import React, { useCallback, useEffect, useState } from 'react'
import { Bounds, CategorySelection, LocationSelection, ProjectSearch, SeoText } from 'discover'
import { Hero, I18nHtml, QueryHelper, SanitizationPresets, Search, TrustedHtml, history } from 'shared'
import { ProjectPageProps, SearchChangeHandler, SearchState } from './types'

export const ProjectsPage = ({
  apiBaseUrl,
  categories,
  defaultHeaderImageUrl,
  initialCategoryId,
  locations,
  banner,
}: ProjectPageProps): JSX.Element | null => {
  const [search, setSearch_] = useState<SearchState | undefined>()

  // when search settings are made, track and push to history stack
  const setSearch: SearchChangeHandler = useCallback(({ ...newSearch }, pushHistory = true) => {
    setSearch_((oldSearch) => {
      const nextSearch = { ...(oldSearch ?? {}), ...newSearch }
      if (pushHistory) {
        const urlSearch = searchToURL(nextSearch)
        const pathnameWithoutPermalink = window.location.pathname.replace(/\/\d.*/, '')
        history.push({ pathname: pathnameWithoutPermalink, search: urlSearch })
      }
      return nextSearch
    })
  }, [])

  // set up with search settings from url params and add history listener
  // to allow restoring previous search states with back button.
  useEffect(() => {
    const newSearch = searchFromURL()
    if (initialCategoryId) newSearch.categoryId = initialCategoryId
    setSearch(newSearch, false)

    history.listen((_, action) => {
      if (action === 'POP') {
        const search = searchFromURL()
        setSearch(search, false)
      }
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const queryChanged = useCallback((query: string) => setSearch({ query }), [setSearch])

  const boundsChanged = useCallback((bounds: Bounds | undefined) => setSearch({ bounds }), [setSearch])

  const categoryChanged = useCallback((categoryId?: number) => setSearch({ categoryId }), [setSearch])

  // wait until search settings are initialized
  if (!search) return null

  const activeCategory = (search && search.categoryId && categories.find((c) => c.id == search.categoryId)) || undefined

  return (
    <div className="mb-12">
      {activeCategory ? (
        <Hero
          imageUrl={activeCategory.headerImageUrl}
          title={activeCategory.name}
          className="preserves-space-for-transparent-header"
        />
      ) : (
        <Hero
          imageUrl={defaultHeaderImageUrl}
          title={i18n.t('discover.categories.index.find_your_project')}
          className="preserves-space-for-transparent-header"
        />
      )}

      {banner && (
        <TrustedHtml as="div" sanitizationPreset={SanitizationPresets.AllowClassesAndLinks}>
          {banner}
        </TrustedHtml>
      )}

      <div className="content-wrapper">
        <div className="container pt-6">
          <div className="row">
            <div className="col-md-16 offset-md-4 flex flex-col desktop:flex-row">
              <div className="desktop:w-px desktop:basis-1/2 grow shrink-0 px-3 mb-3">
                <label>{i18n.t('discover.categories.index.filter_bar_parts.category')}</label>
                <CategorySelection
                  categories={categories}
                  categoryChanged={categoryChanged}
                  categoryId={search.categoryId}
                />
              </div>
              <div className="desktop:w-px desktop:basis-1/2 grow shrink-0 px-3 mb-3">
                <label>{i18n.t('discover.categories.index.filter_bar_parts.location')}</label>
                <LocationSelection bounds={search.bounds} boundsChanged={boundsChanged} locations={locations} />
              </div>
            </div>
          </div>

          <div className="row">
            <div className="col-md-16 offset-md-4 flex mb-6 flex-col desktop:flex-row">
              <div className="flex-1 px-3">
                <div className="search-wrapper">
                  <Search
                    query={search.query}
                    queryChanged={queryChanged}
                    label={i18n.t('discover.categories.index.filter_bar_parts.search')}
                  />
                </div>
                <I18nHtml
                  className="link-gently text-muted text-sm mt-1 block"
                  i18nKey="discover.help_link_search_rank"
                />
              </div>
            </div>
          </div>

          <SeoText text={activeCategory?.seoText1} />

          <ProjectSearch apiBaseUrl={apiBaseUrl} {...search} />

          <SeoText text={activeCategory?.seoText2} />
        </div>
      </div>
    </div>
  )
}

const searchFromURL = () => {
  const params = QueryHelper.parseQuery()
  const bounds = Bounds.fromParams(params.bounds, params.boundsLabel)
  const categoryId = +(params.categoryId ?? 0) || undefined
  const query = params.q

  return { bounds, categoryId, query }
}

const searchToURL = ({ bounds: bounds_, categoryId, query: q }: SearchState): string => {
  const bounds = bounds_ && bounds_.toParams()
  return QueryHelper.toQuery({ q, categoryId, bounds })
}
