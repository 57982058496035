import React, { useCallback } from 'react'

import Geosuggest, { Suggest } from 'react-geosuggest'

import { Bounds } from 'discover'
import { Dropdown } from '../dropdown'
import { LocationSelectionModalContentProps, LocationSelectionProps } from './types'
import { useEffect, useRef } from 'react'
import { useModal } from 'shared'

const getBoundsFromSuggest = (obj: Suggest, neutralLabel: string): Bounds | undefined => {
  // If the user accepts/tabs an empty field, we select the "neutral" thing
  // by default
  const label = obj.label || neutralLabel
  if (label === neutralLabel) return
  if (obj.gmaps?.geometry.bounds) return Bounds.fromGoogleBounds(obj.gmaps.geometry.bounds, label)
  if (obj.gmaps?.geometry.viewport) return Bounds.fromGoogleBounds(obj.gmaps.geometry.viewport, obj.label)
  return
}
export const LocationSelection = ({
  dropdownLabel: dropdownLabel_,
  bounds,
  locations,
  boundsChanged,
}: LocationSelectionProps): JSX.Element => {
  const modal = useModal()
  const neutralLabel = i18n.t('discover.categories.index.all_locations')
  const onSelect = useCallback(
    (obj?: Suggest) => {
      // We need to check for the object here because onSuggestSelect is fired when there is no user input: https://github.com/ubilabs/react-geosuggest/issues/373
      if (obj === undefined) return
      const bounds = getBoundsFromSuggest(obj, neutralLabel)
      modal.hide()
      boundsChanged(bounds)
    },
    [boundsChanged, modal, neutralLabel]
  )
  const options = [{ title: neutralLabel }].concat(locations)
  const dropdownLabel = dropdownLabel_ || (bounds ? bounds.label : neutralLabel)

  return (
    <Dropdown id="location-selection" limitedWidth={true} label={dropdownLabel} modal={modal}>
      <ModalContent options={options} onSelect={onSelect} />
    </Dropdown>
  )
}

const ModalContent = ({ options, onSelect }: LocationSelectionModalContentProps): JSX.Element => {
  const ref: React.MutableRefObject<Geosuggest | null> = useRef(null)
  useEffect(() => {
    ref.current && ref.current.focus()
  }, [])
  // TODO check whether it was supposed to be updatable element as ref.current as dependency won't trigger re-render

  const neutralLabel = i18n.t('discover.categories.index.all_locations')

  const biasBounds = new google.maps.LatLngBounds({ lat: 47, lng: 6 }, { lat: 55, lng: 15 })

  const fixtures = options.map((l) => ({
    label: l.title,
    className: 'as-column',
    // Selecting the neutral label would not trigger the `onSelect` callback,
    // because "All places" is not correctly geo-dereferenced. If we give it the
    // default location, it works.
    location: l.title == neutralLabel ? biasBounds.getSouthWest() : undefined,
  }))

  return (
    <Geosuggest
      bounds={biasBounds}
      fixtures={fixtures}
      onSuggestSelect={onSelect}
      placeholder={i18n.t('discover.categories.index.location_input_hint')}
      types={['geocode']}
      ref={ref}
      autoActivateFirstSuggest
    />
  )
}
