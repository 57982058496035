import React, { useCallback } from 'react'
import { CategoryChangeHandler, CategorySelectionProps, ModalContentProps } from './types'
import { Dropdown } from '../dropdown/dropdown'
import { scrollToTop, sortCategories, useModal } from 'shared'

export const CategorySelection = ({
  categories: unfilteredCategories,
  categoryChanged,
  categoryId,
}: CategorySelectionProps): JSX.Element => {
  const modal = useModal()

  const onCategoryChange: CategoryChangeHandler = useCallback(
    (newCategoryId) => {
      categoryChanged(newCategoryId)
      scrollToTop()
      modal.hide()
    },
    [modal, categoryChanged]
  )
  const categories = unfilteredCategories.filter((c) => c.showInList)
  const neutralLabel = i18n.t('discover.categories.index.all_categories')
  const options = [{ name: neutralLabel }, ...sortCategories(categories)]
  const activeLabel = (categoryId && categories.find((c) => c.id == categoryId)?.name) || neutralLabel

  return (
    <Dropdown id="category-selection" label={activeLabel} modal={modal}>
      <ModalContent modal={modal} options={options} onCategoryChange={onCategoryChange} />
    </Dropdown>
  )
}

const ModalContent = ({ modal, options, onCategoryChange }: ModalContentProps): JSX.Element | null => {
  if (!modal.active) return null

  const categoryOptions = options.map((category) => (
    <li className="geosuggest__item as-column" key={category.id ?? 0}>
      <button onClick={() => onCategoryChange(category.id)}>{category.name}</button>
    </li>
  ))

  return <ul className="geosuggest__suggests">{categoryOptions}</ul>
}
