import React from 'react'
import classNames from 'classnames'
import styles from './dropdown.module.css'
import { DropdownButton } from 'shared'
import { Modal } from 'shared/hooks/use_modal/types'

export const Dropdown = ({
  id,
  modal,
  children,
  limitedWidth,
  label,
}: {
  id: string
  modal: Modal
  children: React.ReactNode
  limitedWidth?: boolean
  label: string
}) => {
  return (
    <div id={id} className={classNames(styles.container, limitedWidth && styles.limitedWidth)}>
      <DropdownButton onClick={modal.show}>{label}</DropdownButton>
      {modal.background}
      {modal.active ? (
        <div className={classNames(styles.modal, limitedWidth && styles.topPositioned)}>{children}</div>
      ) : null}
    </div>
  )
}
